<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="廃棄物名称登録" />
    <AppSideMenu />
    <main class="l-main">
      <div class="l-container l-box">
        <ErrorMessage :errMsgs="errMsgs" />
        <div class="c-form__reqTxt l-item">：入力必須</div>
        <div class="l-contents">
          <div class="l-box">
            <h3 class="c-lead icon">品目情報</h3>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>廃棄物種類</label>
            </div>
            <div class="c-inputWrap__items">
              {{ form.selectedCba.jwnetItem }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>CBA品目名称</label>
            </div>
            <div class="c-inputWrap__items">
              {{ form.selectedCba.cbaName }}
            </div>
          </div>
          <br />
          <div v-for="(waste, index) in form.wasteNameCreateList" :key="index">
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>廃棄物名称</label>
              </div>
              <div class="c-inputWrap__items u-space-btwn">
                <InputText
                  :value.sync="waste.name"
                  inputType="text"
                  placeholder="廃棄物名称を入力"
                  tooltip="50字以内で入力してください"
                  :validation="validateMaxLen"
                  :validateArg="50"
                  :isValid.sync="validate.wastes[index].name.isValid"
                  :errMsg.sync="validate.wastes[index].name.errMsg"
                />
                <div>
                  <div
                    v-if="form.wasteNameCreateList.length !== 1"
                    class="c-btn secondary sm delete"
                    @click="deleteWasteList(index)"
                  >
                    削除
                  </div>
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>備考</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="l-area">
                  <MyTextarea
                    :value.sync="waste.remarks"
                    rows="6"
                    placeholder="備考を入力"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="l-each sm l-box">
            <div class="c-btn secondary func add" @click="addWasteList">
              廃棄物名称を追加
            </div>
          </div>
        </div>
      </div>
      <div class="l-btns l-container">
        <div class="left">
          <div class="c-btn secondary small back" @click="backHandler">戻る</div>
        </div>
        <div class="center">
          <div class="c-btn primary mid" @click="formHandler">登録</div>
        </div>
      </div>
    </main>

    <!-- 登録モーダル -->
    <div class="c-modal" v-show="isDisplayConfirm">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          廃棄物名称を登録します。<br />
          よろしいですか？
        </div>
        <ModalFooterButtons :processing="processing">
          <div class="c-btn secondary small" @click="closeConfirmModalHandler">戻る</div>
          <div class="c-btn primary small" @click="registerHandler">はい</div>
        </ModalFooterButtons>
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import InputText from "@/components/parts/input/InputText";
import MyTextarea from "@/components/parts/input/MyTextarea";
import validation from "@/mixin/validation";
import formValue from "../mixins/formValue";
import callApi from "../mixins/callApi";
import formValidation from "../mixins/form/formValidation";
import axios from "axios";
import {API_URL} from "@/const";
import ModalFooterButtons from "@/components/layout/ModalFooterButtons";
import common from "@/mixin/common";

export default {
  name: "wastename-input",
  components: {
    ModalFooterButtons,
    InputText,
    MyTextarea,
  },
  mixins: [validation, formValue, callApi, formValidation, common],
  data() {
    return {
      isDisplayConfirm: false,
      errMsgs: [],
      cbaItemId: null,
      processing: false,
    };
  },

  methods: {
    closeConfirmModalHandler() {
      this.isDisplayConfirm = false;
    },

    registerHandler() {
      this.processing = true; // 2重submit防止

      axios.post(API_URL.WASTEITEM.WASTENAME,
    {
            wasteNameCreateList: this.form.wasteNameCreateList.map(wn => {
              return {
                cbaItemId: this.cbaItemId,
                wasteName: this.toFullWidthString(wn.name),
                remarks: wn.remarks,
              }
            })
        })
        .then(() => {
          this.$router.push("/cbaitem");
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          this.processing = false; // 2重submit解除
          this.isDisplayConfirm = false;
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },

    formHandler() {
      this.errMsgs = [];
      if (!this.checkInput()) {
        this.processing = false;
        this.errMsgs.push("入力に誤りがあります。");
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }
      this.isDisplayConfirm = true;
    },

    // 戻る押下（遷移元に戻る）
    backHandler() {
      this.$router.go(-1);
    },
    addWasteList() {
      const newList = {
        name: "",
        remarks: "",
      };
      const newValidate = {
        name: { isValid: true, errMsg: "" },
      };
      this.form.wasteNameCreateList = [...this.form.wasteNameCreateList, newList];
      this.validate.wastes = [...this.validate.wastes, newValidate];
    },
    deleteWasteList(index) {
      this.form.wasteNameCreateList.splice(index, 1);
      this.validate.wastes.splice(index, 1);
    },
  },
  mounted() {
    this.cbaItemId = this.$route.query.cbaitemId;
    this.getCbaItemsApi(this.cbaItemId);
  },
};
</script>
